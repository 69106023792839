import React from "react";
import { useLocation } from "react-router-dom";
import Styles from "./main.module.scss";

export default function MainLayout({ children }) {
  const { pathname } = useLocation();
  return (
    <div
      className={`${Styles.container} ${
        pathname === "/" || (pathname === "/company" && "show-upper-bg")
      }`}
    >
      {children}
    </div>
  );
}
