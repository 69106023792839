import React from "react";
import HeroSection from "../../components/sections/payments/HeroSection";
import { Grid } from "semantic-ui-react";
// style
import Styles from "./security.module.scss";
// images
import SecurityCameras from "../../assets/security-cameras.png";
import CreditCard from "../../assets/credit-card-payment.png";
import CreditCardImg from "../../assets/woman-smilling.png";
// icons
import { ReactComponent as CertifiedIcon } from "../../assets/icons/certified.svg";
import { ReactComponent as TokenIcon } from "../../assets/icons/token.svg";
import { ReactComponent as AuthenticationIcon } from "../../assets/icons/authentication.svg";
import { ReactComponent as MonitorIcon } from "../../assets/icons/monitor.svg";

export default function Security() {
  return (
    <div className={Styles.container}>
      <HeroSection
        noSecondaryBtn
        title="PayInvert — Secure by Default"
        desc="At PayInvert, we have built powerful security technologies and protections into our payment platform so you can rest assured of your safety and that of your customers. "
        img1={SecurityCameras}
        img2={CreditCard}
        img3={CreditCardImg}
      />
      <Grid
        stackable
        centered
        columns="equal"
        relaxed
        className={Styles.featureWrapper}
      >
        <Grid.Row>
          <Grid.Column className={Styles.feature} data-aos="fade-bottom">
            <div>
              <CertifiedIcon />
              <h3>PCI-DSS Certified</h3>
            </div>
            <p>
              PayInvert is PCI-DSS Certified, meaning any eligible card from
              your customers can be charged with transactions secured against
              data theft and fraud. This is the very first step to keeping your
              usage of our platform risk-mitigated and we continue to keep
              compliant with the rigors of this data security standard.
            </p>
          </Grid.Column>
          <Grid.Column
            className={Styles.feature}
            data-aos="fade-bottom"
            data-aos-delay={100}
          >
            <div>
              <TokenIcon />
              <h3>Tokenization</h3>
            </div>
            <p>
              We securely encrypt and store your customers’ payment information
              in our PCI-compliant vault so that your system can easily
              authenticate them for recurring or subscription-type payment. This
              makes the payment experience frictionless both for you and those
              who enjoy buying from or paying you.
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
            className={Styles.feature}
            data-aos="fade-bottom"
            data-aos-delay={200}
          >
            <div>
              <AuthenticationIcon />
              <h3>Multifactor Authentication</h3>
            </div>
            <p>
              At PayInvert, we go beyond username and password credentials or
              other basic information authentication measures. We stack multiple
              of these authentication factors together so that where one is
              weak, the other may compensate, and overall data breaches are
              forestalled.
            </p>
          </Grid.Column>
          <Grid.Column
            className={Styles.feature}
            data-aos="fade-bottom"
            data-aos-delay={300}
          >
            <div>
              <MonitorIcon />
              <h3>Advanced Fraud Monitoring</h3>
            </div>
            <p>
              You don’t need to understand the ins-and-outs of the technology or
              define a set of rules. We continue to monitor fraud signatures and
              help squelch malicious attempts to compromise a payment flow, even
              before you know it.
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}
