import React from "react";
import ExploreSection from "../components/sections/homepage/ExploreSection";
import HeroSection from "../components/sections/homepage/HeroSection";
import WhyPayInvert from "../components/sections/homepage/WhyPayInvert";
import { Helmet } from "react-helmet";

function HomePage() {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>PayInvert - Secure Payment Solutions in Nigeria</title>
        <meta name="description" content="Get secure and convenient payment solutions for your business in Nigeria with PayInvert. Our platform offers a range of payment options, including online payments, card payments, and payment links." />
        <meta name="keywords" content="FINTECH, payment solutions, Nigeria, online payments, card payments, payment links." />
      </Helmet>
      <HeroSection />
      <WhyPayInvert />
      <ExploreSection />
    </>
  );
}
export default HomePage;
