import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// layouts
import MainLayout from "../layouts/main";
// sections
import NavBar from "../components/navbar/navbar";
import CTASection from "../components/sections/CTASection";
import FooterSection from "../components/sections/FooterSection";
// pages
import HomePage from "../pages/HomePage";
import Payments from "../pages/Payments/Payments";
import PaymentLinks from "../pages/PaymentLinks/PaymentLinks";
import Security from "../pages/Security/Security";
import Pricing from "../pages/Pricing/Pricing";
import Company from "../pages/Company/Company";
import TermsController from "../pages/Terms/TermsController";

export default function AppRoutes() {
  return (
    <Router>
      <MainLayout>
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/payment-links" element={<PaymentLinks />} />
          <Route path="/security" element={<Security />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/company" element={<Company />} />
          <Route path="/terms-of-use" element={<TermsController />} />
        </Routes>
        <CTASection />
        <FooterSection />
      </MainLayout>
    </Router>
  );
}
