import React from "react";
import { Grid } from "semantic-ui-react";
//import { Grid, Image } from "semantic-ui-react";
import Styles from "./pricing.module.scss";
import PrimaryBtn from "../../components/buttons/primaryBtn";
// icons
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/checkmark.svg";
import { ReactComponent as DoubleArrowRight } from "../../assets/icons/double-arrow-right.svg";
//import CarbonLogo from "../../assets/icons/carbon-logo.png";
//import GigmLogo from "../../assets/icons/gigm-logo.png";
//import IrokoLogo from "../../assets/icons/iroko-logo.png";
//import KudaLogo from "../../assets/icons/kuda-logo.png";
//import PiggyvestLogo from "../../assets/icons/piggyvest-logo.png";
// list icons
import { ReactComponent as CertifiedIcon } from "../../assets/icons/certified.svg";
import { ReactComponent as TokenIcon } from "../../assets/icons/token.svg";
import { ReactComponent as AuthenticationIcon } from "../../assets/icons/authentication.svg";
import { ReactComponent as MonitorIcon } from "../../assets/icons/monitor.svg";
import { Helmet } from "react-helmet";

export default function Pricing() {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Pricing - PayInvert</title>
        <meta name="description" content="View our pricing plans for our payment solutions, including merchant services and payment link solutions. Choose the plan that fits your needs and budget." />
        <meta name="keywords" content="FINTECH, payment solutions, pricing plans, merchant services, payment links." />
      </Helmet>
      <div className={Styles.container}>
        <di className={Styles.header}>
          <h1 data-aos="fade-bottom">Simple, pay as you go</h1>
          <p data-aos="fade-bottom" data-aos-delay={100}>
            Our pricing is designed to support your business at any level of
            growth, you see exactly what you pay, nothing hidden, ever.{" "}
          </p>
        </di>
        <div className={Styles.platform}>
          <div className={Styles.content}>
            <h3 data-aos="fade-bottom">Platform</h3>
            <h2 data-aos="fade-bottom" data-aos-delay={100}>
              1.4%
            </h2>
            <span data-aos="fade-bottom" data-aos-delay={150}>
              per successful transaction
            </span>
            <h3
              data-aos="fade-bottom"
              data-aos-delay={200}
              className={Styles.desc}
            >
              Access a complete payments platform with simple, pay-as-you-go
              pricing
            </h3>
            <div className={Styles.list}>
              <div data-aos="fade-bottom" data-aos-delay={100}>
                <div className={Styles.iconWrapper}>
                  <CheckmarkIcon />
                </div>
                <h3>Everything you need to manage payments</h3>
              </div>
              <div data-aos="fade-bottom" data-aos-delay={200}>
                <div className={Styles.iconWrapper}>
                  <CheckmarkIcon />
                </div>
                <h3>Get hundreds of feature updates each year</h3>
              </div>
              <div data-aos="fade-bottom" data-aos-delay={300}>
                <div className={Styles.iconWrapper}>
                  <CheckmarkIcon />
                </div>
                <h3>No setup fees, monthly fees, or hidden fees</h3>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.custom}>
          <div className={Styles.content}>
            <h3 data-aos="fade-bottom">Custom</h3>
            <h3
              data-aos="fade-bottom"
              data-aos-delay={100}
              className={Styles.title}
            >
              Design a custom package for your business
            </h3>
            <h3
              data-aos="fade-bottom"
              data-aos-delay={200}
              className={Styles.desc}
            >
              Available for businesses with large payments volume, high value
              transactions, or unique business models
            </h3>
            <div className={Styles.list}>
              <div data-aos="fade-bottom" data-aos-delay={100}>
                <div className={Styles.iconWrapper}>
                  <CheckmarkIcon />
                </div>
                <h3>Volume discounts</h3>
              </div>
              <div data-aos="fade-bottom" data-aos-delay={200}>
                <div className={Styles.iconWrapper}>
                  <CheckmarkIcon />
                </div>
                <h3>Multi-product discounts</h3>
              </div>
              <div data-aos="fade-bottom" data-aos-delay={300}>
                <div className={Styles.iconWrapper}>
                  <CheckmarkIcon />
                </div>
                <h3>Country-specific rates</h3>
              </div>
            </div>
            <PrimaryBtn data-aos="fade-bottom" link="mailto:sales@payinvert.com">
              Contact sales <DoubleArrowRight />
            </PrimaryBtn>
          </div>
        </div>
        <Grid
          stackable
          centered
          columns="equal"
          relaxed
          className={Styles.featureWrapper}
        >
          <Grid.Row className={Styles.featureWrapperRow}>
            <Grid.Column className={Styles.feature} data-aos="fade-bottom">
              <div>
                <CertifiedIcon />
                <h3>Fully flat rate</h3>
              </div>
              <p>
                As clear as day, what you see is what you pay and we never charge
                anything hidden from you.
              </p>
            </Grid.Column>
            <Grid.Column
              className={Styles.feature}
              data-aos="fade-bottom"
              data-aos-delay={100}
            >
              <div>
                <TokenIcon />
                <h3>No setup fee</h3>
              </div>
              <p>
                You only pay per transaction, that’s all. No setup fee, no monthly
                maintenance fee.
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={Styles.featureWrapperRow}>
            <Grid.Column
              className={Styles.feature}
              data-aos="fade-bottom"
              data-aos-delay={200}
            >
              <div>
                <AuthenticationIcon />
                <h3>Accurate data and reporting</h3>
              </div>
              <p>
                We provide advanced analytics on how your business is doing on our
                beautiful business dashboard. Your transaction data is always
                yours.
              </p>
            </Grid.Column>
            <Grid.Column
              className={Styles.feature}
              data-aos="fade-bottom"
              data-aos-delay={300}
            >
              <div>
                <MonitorIcon />
                <h3>Single, simple all-in-one integration</h3>
              </div>
              <p>
                You will never have to cobble a bunch of tools together for
                different payment methods or types or to satisfy different
                customer preferences. One single, simple integration of PayInvert
                and you’re done.
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={Styles.featureWrapperRow}>
            <Grid.Column
              className={Styles.feature}
              data-aos="fade-bottom"
              data-aos-delay={200}
            >
              <div>
                <AuthenticationIcon />
                <h3>Flexible settlements</h3>
              </div>
              <p>Choose when and how you want to be settled.</p>
            </Grid.Column>
            {/* for spacing */}
            <Grid.Column></Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid
          stackable
          centered
          columns="equal"
          relaxed
          className={Styles.questionsWrapper}
        >
          <Grid.Row>
            <Grid.Column className={Styles.feature} data-aos="fade-bottom">
              <div>
                <CertifiedIcon />
                <h3>Do you charge setup or monthly fees?</h3>
              </div>
              <p>
                No and no. PayInvert is 100% free to integrate and we charge
                nothing monthly while you use our platform. We only get paid when
                you do.
              </p>
            </Grid.Column>
            <Grid.Column
              className={Styles.feature}
              data-aos="fade-bottom"
              data-aos-delay={100}
            >
              <div>
                <TokenIcon />
                <h3>Do you give discounts based on transaction volume?</h3>
              </div>
              <p>
                Yes, please. You get a discount on the transaction fee as your
                transaction volume grows. Kindly contact sales for more
                information.
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              className={Styles.feature}
              data-aos="fade-bottom"
              data-aos-delay={200}
            >
              <div>
                <AuthenticationIcon />
                <h3>Do you support multiple currencies?</h3>
              </div>
              <p>
                We can link to multiple bank accounts with multiple currencies, so
                we don’t have to convert the currency.
              </p>
            </Grid.Column>
            <Grid.Column
              className={Styles.feature}
              data-aos="fade-bottom"
              data-aos-delay={300}
            >
              <div>
                <MonitorIcon />
                <h3>How long does it take for me to get my money?</h3>
              </div>
              <p>
                Your money is automatically settled to your bank account the next
                working day. Although, there are cases where settlements may be
                earlier or later. Please contact sales for more information
                regarding this.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </>
  );
}
