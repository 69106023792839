import React, { useEffect, useState } from "react";
import Styles from "./navbar.module.scss";
import PayInvertLogo from "../../assets/logo-primary.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PrimaryBtn from "../buttons/primaryBtn";
import { ReactComponent as MenuIcon } from "../../assets/icons/mobile-menu.svg";
import Zoom from "react-reveal/Zoom"; // Importing Zoom effect
import { useMediaQuery } from "react-responsive";
import SecondaryBtn from "../buttons/secondaryBtn.tsx";

export default function NavBar() {
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [showMenu, setShowMenu] = useState(false);

  const location = useLocation();

  const navigate = useNavigate();

  const isMobile = useMediaQuery({ query: "(max-width: 900px)" });

  useEffect(() => {
    const controlNavbar = () => {
      if (typeof window !== "undefined") {
        setShowMenu(false);
        if (window.scrollY > lastScrollY) {
          // if scroll down hide the navbar
          setShow(false);
        } else {
          // if scroll up show the navbar
          setShow(true);
        }
        setLastScrollY(window.scrollY);
      }
    };
    // check scrolled position
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  const toggleMenu = () => {
    setShowMenu((prev) => !prev);
  };

  return (
    <div
      className={Styles.container}
      style={{
        opacity: show ? 1 : 0,
        transform: show ? "translateY(0%)" : "translateY(-100%)",
        background: lastScrollY === 0 ? "transparent" : "#fff",
      }}
    >
      <div>
        <div onClick={() => navigate("/")} className={Styles.logoWrapper}>
          <img src={PayInvertLogo} alt="Pay Invert Logo" />
        </div>
        <div className="mobile-only">
          <SecondaryBtn>
            <a
              href="https://merchant.payinvert.com/"
              target="_blank"
              rel="noreferrer"
            >
              Log in
            </a>
          </SecondaryBtn>
        </div>
        <Zoom right cascade when={showMenu} duration={isMobile ? 600 : 0}>
          <nav
            style={{ display: !showMenu && isMobile && "none" }}
            onClick={toggleMenu}
          >
            <li
              className={location.pathname === "/payments" ? Styles.active : ""}
            >
              <Link to="/payments">Payments</Link>
            </li>
            <li
              className={
                location.pathname === "/payment-links" ? Styles.active : ""
              }
            >
              <Link to="/payment-links">Payment Links</Link>
            </li>
            <li
              className={location.pathname === "/security" ? Styles.active : ""}
            >
              <Link to="/security">Security</Link>
            </li>
            <li
              className={location.pathname === "/pricing" ? Styles.active : ""}
            >
              <Link to="/pricing">Pricing</Link>
            </li>
            <li
              className={location.pathname === "/company" ? Styles.active : ""}
            >
              <Link to="/company">Company</Link>
            </li>
            <div className={Styles.userMenuMobile}>
              <PrimaryBtn>
                <a
                  href="https://merchant.payinvert.com/sign-up"
                  target="_blank"
                  rel="noreferrer"
                >
                  Get started
                </a>
              </PrimaryBtn>
            </div>
          </nav>
        </Zoom>
      </div>
      <div
        onClick={() => setShowMenu((prev) => !prev)}
        className={Styles.mobileMenuContainer}
      >
        <MenuIcon />
      </div>
      <div className={Styles.userMenu}>
        <SecondaryBtn link="https://merchant.payinvert.com">
          Log in
        </SecondaryBtn>
        <PrimaryBtn link="https://merchant.payinvert.com/sign-up">
          Get started
        </PrimaryBtn>
      </div>
    </div>
  );
}
