import React from "react";
import { Link } from "react-router-dom";
import Styles from "./footer.module.scss";
import { ReactComponent as CopyRightIcon } from "../../assets/icons/copyright.svg";
export default function FooterSection() {
  const scrollTopPage = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className={Styles.container}>
      <div className={Styles.sqaures}></div>
      <div className={Styles.productSection}>
        <h3>Product</h3>
        <ul>
          <li>
            <Link to="/payments" onClick={scrollTopPage}>
              Payments
            </Link>
          </li>
          <li>
            <Link to="/payment-links" onClick={scrollTopPage}>
              Payment Links
            </Link>
          </li>
          <li>
            <Link to="/security" onClick={scrollTopPage}>
              Fraud Prevention
            </Link>
          </li>
          <li>
            <Link to="/pricing" onClick={scrollTopPage}>
              Pricing
            </Link>
          </li>
          <li>
            <Link to="/terms-of-use" onClick={scrollTopPage}>
              Terms & Conditions
            </Link>
          </li>
        </ul>
      </div>
      <div className={Styles.integrationSection}>
        <h3>Integration</h3>
        <ul>
          <li>
            <a
              href="https://docs.payinvert.com"
              target="_blank"
              rel="noreferrer"
            >
              Documentation
            </a>
          </li>
          <li>
            <a
              href="https://docs.payinvert.com"
              target="_blank"
              rel="noreferrer"
            >
              API Reference
            </a>
          </li>
          <li>
            <Link to="/">Support</Link>
          </li>
          <li>
            <Link to="/">Status</Link>
          </li>
        </ul>
      </div>
      <div className={Styles.integrationSection}>
        <h3>Company</h3>
        <ul>
          <li>
            <Link to="/company" onClick={scrollTopPage}>
              About Us
            </Link>
          </li>
          <li>
            <Link to="mailto:contact@payinvert.com" target="_blank">
              Contact Us
            </Link>
          </li>
          <li>
            <a
              href="https://twitter.com/PayInvert"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/payinvert"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
          </li>
        </ul>
      </div>
      <div className={Styles.copyRight}>
        <ul>
          <li>
            <Link to="/">Terms of Use</Link>
          </li>
          <li>
            <Link to="/">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/">Cookie Policy</Link>
          </li>
        </ul>
        <div className={Styles.reserved}>
          <span>
            Copyright {<CopyRightIcon />} {new Date().getFullYear()} PayInvert
            Limited
          </span>
          <span>All rights reserved.</span>
        </div>
      </div>
    </div>
  );
}
