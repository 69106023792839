import React from "react";
import HeroSection from "../../components/sections/payments/HeroSection";
import Styles from "./paymentLinks.module.scss";
import { Grid, Image } from "semantic-ui-react";
// images
import Checkout from "../../assets/checkout-frame.png";
import CreditCard from "../../assets/credit-card-payment.png";
import CreditCardImg from "../../assets/credit-wallet.png";
import { Helmet } from "react-helmet";

export default function PaymentLinks() {
  return (
    <div className={Styles.container}>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Payment Links - PayInvert</title>
        <meta name="description" content="Easily create and send payment links to customers with our payment link solution. Our secure and easy-to-use platform makes it simple to get paid quickly and efficiently." />
        <meta name="keywords" content="FINTECH, payment links, online payments, payment solutions, invoicing." />
      </Helmet>
      <HeroSection
        noSecondaryBtn
        title="Sell anywhere you want with just a link, no code needed"
        desc="PayInvert Payment Links lets you sell online without a website. With just a few clicks you can get started with a link, share with your customers wherever they are, and start selling."
        img1={Checkout}
        img2={CreditCard}
        img3={CreditCardImg}
      />
      <Grid stackable centered columns="equal" className={Styles.howItWorks}>
        <Grid.Row>
          <Grid.Column className={Styles.content}>
            <h2 data-aos="fade-bottom">How it works</h2>
            <p data-aos="fade-bottom" data-aos-delay={100}>
              Start accepting payments with just a few clicks
            </p>
            <div className={Styles.imgWrapper}>
              <Image data-aos="zoom-out-up" src={Checkout} />
              <Image
                data-aos="zoom-out-up"
                data-aos-delay={100}
                src={CreditCardImg}
              />
              <Image
                data-aos="zoom-out-up"
                data-aos-delay={200}
                src={CreditCard}
              />
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={Styles.listWrapper}>
              <div>
                <h1 data-aos="zoom-out-up">1</h1>
                <div className={Styles.list}>
                  <h3 data-aos="fade-bottom" data-aos-delay={200}>
                    Create link
                  </h3>
                  <p data-aos="fade-bottom" data-aos-delay={300}>
                    From your business dashboard, you can easily create a link
                    to sell a product or service, start a subscription, or
                    collect a donation. Your link will carry your logo and be
                    personal to you. You can always add products to your links,
                    change what type of payment it is, and do a lot more.
                    Payment Link is like your digital store without you writing
                    a line of code.
                  </p>
                </div>
              </div>
              <div>
                <div className={Styles.list}>
                  <h3 data-aos="fade-bottom" data-aos-delay={400}>
                    Share link
                  </h3>
                  <p data-aos="fade-bottom" data-aos-delay={500}>
                    Take your Payment Links wherever you go or wherever your
                    customers are. Twitter, Facebook, Instagram, email,
                    anywhere, across multiple channels, you can share or embed
                    your links and get on selling.
                  </p>
                </div>
                <h1 data-aos="zoom-out-up" data-aos-delay={600}>
                  2
                </h1>
              </div>
              <div>
                <h1
                  data-aos-offset="-200"
                  data-aos="zoom-out-up"
                  data-aos-delay={700}
                >
                  3
                </h1>
                <div className={Styles.list}>
                  <h3
                    data-aos-offset="-200"
                    data-aos="fade-bottom"
                    data-aos-delay={800}
                  >
                    Get paid
                  </h3>
                  <p
                    data-aos-offset="-200"
                    data-aos="fade-bottom"
                    data-aos-delay={900}
                  >
                    You get paid as soon as your customers buy or pay for a
                    service or a donation at your link.
                  </p>
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}
