import React from 'react'
import { Card, Grid } from 'semantic-ui-react'
import Styles from "./explore.module.scss"
import { ReactComponent as DoubleArrowRight } from "../../../assets/icons/double-arrow-right.svg"
import { ReactComponent as ShieldIcon } from "../../../assets/icons/shield.svg"
import { ReactComponent as CreditCardIcon } from "../../../assets/icons/credit-card.svg"
import { ReactComponent as LinkIcon } from "../../../assets/icons/link.svg"
import { Link } from 'react-router-dom'

export default function ExploreSection() {
    return (
        <div className={Styles.container}>
            <h2 data-aos="fade-bottom">Explore all of PayInvert</h2>
            <Grid stackable columns={3} >
                <Grid.Row className={Styles.exploreCardWrapper}>
                    <Grid.Column>
                        <Card data-aos="fade-bottom" data-aos-delay={100} className={Styles.exploreCard}>
                            <div className={Styles.exploreCardContent}>
                                <div className={Styles.exploreCardIcon}>
                                    <CreditCardIcon />
                                </div>
                                <h3>Payments</h3>
                                <p>Our payments infrastructure is the technology you need the sort of unified commerce that accelerates and sustains business growth. With PayInvert Payments you can start accepting payments now online, offline, and fully embedded into your own platform or marketplace. </p>
                            </div>
                            <Card.Content className={Styles.exploreCardFooter} extra>
                                <Link to="/">
                                    Learn more <DoubleArrowRight />
                                </Link>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                    <Grid.Column className="no-padding">
                        <Card data-aos="fade-bottom" data-aos-delay={200} className={Styles.exploreCard}>
                            <div className={Styles.exploreCardContent}>
                                <div className={Styles.exploreCardIcon}>
                                    <LinkIcon />
                                </div>
                                <h3>Payment Links</h3>
                                <p>PayInvert's Payment Link allows you to sell or collect payments online without a website, operate a mini-store, collect one-off payments such as donations or charities, or even start getting paid on a recurring basis. </p>
                            </div>
                            <Card.Content className={Styles.exploreCardFooter} extra>
                                <Link to="/">
                                    Learn more <DoubleArrowRight />
                                </Link>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                    <Grid.Column>
                        <Card data-aos="fade-bottom" data-aos-delay={300} className={Styles.exploreCard}>
                            <div className={Styles.exploreCardContent}>
                                <div className={Styles.exploreCardIcon}>
                                    <ShieldIcon />
                                </div>
                                <h3>Fraud Prevention</h3>
                                <p>PayInvert's platform has the most advanced and cutting-edge fraud detection and prevention technologies built in to help protect you and your customers.</p>
                            </div>
                            <Card.Content className={Styles.exploreCardFooter} extra>
                                <Link to="/">
                                    Learn more <DoubleArrowRight />
                                </Link>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div >
    )
}
