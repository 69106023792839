import React from "react";
import HeroSection from "../../components/sections/payments/HeroSection";
import Checkout from "../../assets/checkout.png";
import CreditCard from "../../assets/credit-card-payment.png";
import CreditCardImg from "../../assets/credit-wallet.png";
import Styles from "./payments.module.scss";
import Features from "../../components/sections/payments/Features";
import DashboardFeatures from "../../components/sections/payments/DashboardFeatures";
import { Helmet } from "react-helmet";

export default function Payments() {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Payment Products -  PayInvert</title>
        <meta name="description" content="Discover our range of merchant payment products that make it easy and secure for businesses to accept payments. From card readers to online payment gateways, we've got you covered." />
        <meta name="keywords" content="FINTECH, payment solutions, merchant services, card readers, online payments, payment gateways." />
      </Helmet>
      <div className={Styles.heroWrapper}>
        <HeroSection
          title="A complete suite of payment technologies"
          desc="Our payments infrastructure is the technology you need, the sort of unified commerce that accelerates and sustains business growth. With PayInvert Payments, you can start accepting payments now online and offline."
          img1={Checkout}
          img2={CreditCard}
          img3={CreditCardImg}
        />
      </div>
      <Features />
      <DashboardFeatures />
    </>
  );
}
