import React, { useState } from "react";
import { Grid, Image } from "semantic-ui-react";
import PrimaryBtn from "../../components/buttons/primaryBtn";
import Fade from "react-reveal/Fade";
// styles
import Styles from "./controller.module.scss";
// icons
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/double-arrow-down.svg";
// images
import TermImg from "../../assets/term.png";
import TermImg2 from "../../assets/term2.png";
import Sidebar from "../../components/sections/termsOfUse/Sidebar";
import TermsOfUse from "./TermsOfUse";
import Privacy from "./Privacy";
import Cookie from "./cookie";

export default function TermsController() {
  const [selected, setSelected] = useState("terms");

  const updateSelected = (data) => {
    setSelected(data);
  };

  const TermsHandler = () => {
    switch (selected) {
      case "terms":
        return <TermsOfUse />;
      case "privacy":
        return <Privacy />;
      case "cookie":
        return <Cookie />;
      default:
        break;
    }
  };

  return (
    <div className={Styles.container}>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Terms and Conditions - PayInvert</title>
        <meta name="description" content="Read our terms and conditions to learn about our policies regarding the use of our FINTECH products and services." />
        <meta name="keywords" content="FINTECH, terms and conditions, policies, products, services." />
      </Helmet>
      <Grid className={Styles.heroWrapper} centered relaxed columns="2">
        <Grid.Row>
          <Grid.Column width={9} className={Styles.imgWrapper}>
            <Image src={TermImg} />
            <Image src={TermImg2} />
          </Grid.Column>
          <Grid.Column width={7} className={Styles.content}>
            <h1>Legal</h1>
            <p>
              Our trials and triumphs became at once unique and universal, black
              and more than black; in chronicling our journey, the stories and
              songs gave us a means to reclaim memories that we didn't need to
              feel shame about
            </p>
            <PrimaryBtn>
              Read more <ArrowDownIcon />
            </PrimaryBtn>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div className={Styles.main}>
        <Sidebar updateSelected={updateSelected} />
        <Fade bottom cascade>
          <TermsHandler />
        </Fade>
      </div>
    </div>
  );
}
