import React, { useState } from "react";
import Fade from 'react-reveal/Fade';
import Styles from "./features.module.scss";
// slider images
import Image1 from "../../../assets/security-camera.png";
import Image2 from "../../../assets/order_checkout.png";
import Image3 from "../../../assets/shopping_online.png";
// icons
import { ReactComponent as TelePhoneIcon } from "../../../assets/icons/telephone.svg"
import { ReactComponent as UserIcon } from "../../../assets/icons/user.svg"
import { ReactComponent as ShieldIcon } from "../../../assets/icons/shield.svg"
// logos
import CarbonLogo from "../../../assets/icons/carbon-logo.png";
import GigmLogo from "../../../assets/icons/gigm-logo.png";
import IrokoLogo from "../../../assets/icons/iroko-logo.png";
import KudaLogo from "../../../assets/icons/kuda-logo.png";
import PiggyvestLogo from "../../../assets/icons/piggyvest-logo.png";

import { Grid, Image } from "semantic-ui-react";

function Features() {
    const content = [
        {
            title: "Payments for every business",
            text: `Run a full-fledged ecommerce store online or collect one-off or subscription payments. As long as your business interfaces with customers and collects payments, irrespective of platform, PayInvert has your back.`,
            icon: <TelePhoneIcon />
        },
        {
            title: `Easy to get started`,
            text: `We've made it easy to get started so you can get about doing your business and not worry about payments ever again. All you need to is create a free account online (in minutes) and start accepting payments. `,
            icon: <UserIcon />
        },
        {
            title: `Secure, fuels your growth`,
            text: `PayInvert's platform has the most advanced and cutting-edge fraud detection and prevention technologies built in to help protect you and your customers. Collect more revenue and let us worry about any possible hindrance, which we continue to squelch as soon as we discover them. You can trust our automated fraud monitoring system.`,
            icon: <ShieldIcon />
        },
    ];

    const [curPos, setCurPos] = useState(0);
    const [curImage, setCurImage] = useState(Image1);

    const handleMoveSect = (pos) => {
        setCurPos(pos);

        switch (pos) {
            case 0:
                setCurImage(Image1);
                break;
            case 1:
                setCurImage(Image2);
                break;
            case 2:
                setCurImage(Image3);
                break;
            default:
                setCurImage(Image1);
                break;
        }
    };
    return (
        <Grid relaxed stackable columns="equal" className={Styles.container}>
            <Grid.Row>
                <Grid.Column className={Styles.sliderContainer}>
                    <Fade up cascade>
                        {content.map((item, pos) => {
                            return (
                                <div
                                    className={pos === curPos ? Styles.activeSlide : ""}
                                    key={pos}
                                    onClick={() => handleMoveSect(pos)}
                                >
                                    <div className={Styles.iconWrapper}>
                                        {item?.icon}
                                    </div>
                                    <h3>
                                        {item?.title}
                                    </h3>
                                    {<p className="fade-in">{item?.text}</p>}
                                </div>
                            );
                        })}
                    </Fade>
                </Grid.Column>
                <Grid.Column className={Styles.sliderImgWrapper}>
                    <Fade spy={curPos}>
                        <Image src={curImage} alt="accordion-hand" />
                    </Fade>
                    <div className={Styles.trustedLogos}>
                        <span data-aos="fade-bottom" data-aos-offset="-200">Trusted by</span>
                        <div className={Styles.logoWrapper}>
                            <div data-aos="fade-bottom" data-aos-delay={100}>
                                <Image src={CarbonLogo} />
                            </div>
                            <div data-aos="fade-bottom" data-aos-delay={100}>
                                <Image src={GigmLogo} />
                            </div>
                            <div data-aos="fade-bottom" data-aos-delay={200}>
                                <Image src={PiggyvestLogo} />
                            </div>
                            <div data-aos="fade-bottom" data-aos-delay={300}>
                                <Image src={KudaLogo} />
                            </div>
                            <div data-aos="fade-bottom" data-aos-delay={400}>
                                <Image src={IrokoLogo} />
                            </div>
                        </div>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default Features;
