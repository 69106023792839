import React from "react";
import AppRoutes from "./routes/AppRoutes";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {

  AOS.init({
    duration: 600,
    mirror: true,
    easing: "ease-in-sine",
  });
  return (
    <div>
      <AppRoutes />
    </div>
  );
}

export default App;
