import React, { useState } from "react";
import Styles from "./sidebar.module.scss";
import Fade from "react-reveal/Fade";

export default function Sidebar({ updateSelected }) {
  const [selected, setSelected] = useState("terms");

  const selectedHandler = (text) => {
    updateSelected(text);
    setSelected(text);
  };
  return (
    <Fade bottom cascade>
      <ul className={Styles.container}>
        <li
          onClick={() => selectedHandler("terms")}
          className={selected === "terms" ? Styles.active : ""}
        >
          Terms of use
        </li>
        <li
          onClick={() => selectedHandler("privacy")}
          className={selected === "privacy" ? Styles.active : ""}
        >
          Privacy policy
        </li>
        <li
          onClick={() => selectedHandler("cookie")}
          className={selected === "cookie" ? Styles.active : ""}
        >
          Cookie policy
        </li>
      </ul>
    </Fade>
  );
}
