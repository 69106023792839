import React from "react";

import { Link } from "react-router-dom";

import Styles from "./dashboard.module.scss";
// slider images
import DashboardImg from "../../../assets/dashboard-frame.png";
import OrderSummary from "../../../assets/order_summary.png";
import OrderSummary2 from "../../../assets/order_summary2.png";
// icons
import { ReactComponent as CheckmarkIcon } from "../../../assets/icons/checkmark.svg"
import { ReactComponent as ArrowDownIcon } from "../../../assets/icons/double-arrow-down.svg"

import { Grid, Image } from "semantic-ui-react";

function DashboardFeatures() {

    return (
        <Grid relaxed stackable columns="equal" className={Styles.container}>
            <Grid.Row>
                <Grid.Column className={Styles.sliderContainer}>
                    <div data-aos="fade-bottom" >
                        <div className={Styles.iconWrapper}>
                            <CheckmarkIcon />
                        </div>
                        <div className={Styles.content}>
                            <h3>
                                Simple, responsive checkout
                            </h3>
                            <p>
                                Our checkout is simple, pluggable and supports several payment types and currencies, making it easier for customers from everywhere to pay you easily. Your customers can easily toggle on/off and see a quick glance what they’re paying for.
                            </p>
                        </div>
                    </div>
                    <div data-aos="fade-bottom" data-aos-delay={50}>
                        <div className={Styles.iconWrapper}>
                            <CheckmarkIcon />
                        </div>
                        <div className={Styles.content}>
                            <h3>
                                Shareable payment links
                            </h3>
                            <p>
                                PayInvert's Payment Link allows you to sell or collect payments online without a website. Operate a mini-store, collect one-off payments such as donations or charities, or even start getting paid on a recurring basis.
                            </p>
                            <Link to="/">Learn more <ArrowDownIcon /></Link>
                        </div>
                    </div>
                    <div data-aos="fade-bottom" data-aos-delay={100}>
                        <div className={Styles.iconWrapper}>
                            <CheckmarkIcon />
                        </div>
                        <div className={Styles.content}>
                            <h3>
                                Beautiful, intuitive dashboard
                            </h3>
                            <p>
                                Our business dashboard is as beautiful as it gets, and intuitive. Like everything we do at PayInvert, we’ve continued to tweak and improve on the user experience and interface so that you can find and do what you need when you need it and go about your business effortlessly.
                            </p>
                        </div>
                    </div>
                    <div data-aos="fade-bottom" data-aos-delay={150}>
                        <div className={Styles.iconWrapper}>
                            <CheckmarkIcon />
                        </div>
                        <div className={Styles.content}>
                            <h3>
                                Several businesses, one interface
                            </h3>
                            <p>
                                Manage payments from several businesses or subsidiaries of several businesses from one dashboard. We’ve built the PayInvert business dashboard to support chain business, if yours is set up that way, and with ease in navigating among these different units.
                            </p>
                        </div>
                    </div>
                    <div data-aos="fade-bottom" data-aos-delay={200}>
                        <div className={Styles.iconWrapper}>
                            <CheckmarkIcon />
                        </div>
                        <div className={Styles.content}>
                            <h3>
                                Fully certified, standards-compliant
                            </h3>
                            <p>
                                PayInvert continues to take steps to ensure your customers’ payment information are securely encrypted and stored in our industry-standard PCI-compliant vault. Our PCI-DSS certified systems mean that you don’t have to worry about customer information leaks or thefts when processing payments.
                            </p>
                        </div>
                    </div>
                </Grid.Column>
                <Grid.Column className={Styles.sliderImgWrapper}>
                    <Image data-aos="zoom-out-up" src={DashboardImg} alt="payinvert dashboard" />
                    <Image data-aos="zoom-out-up" data-aos-delay={100} src={OrderSummary} alt="payinvert order summary" />
                    <Image data-aos="zoom-out-up" data-aos-delay={150} src={OrderSummary2} alt="payinvert order summary" />
                </Grid.Column>
            </Grid.Row>
        </Grid >
    );
}

export default DashboardFeatures;
