import React from "react";
import { Button } from "semantic-ui-react";
import Styles from "./button.module.scss";

export default function PrimaryBtn({ children, link }) {
  return (
    <Button
      onClick={() => window.open(link, "_blank")}
      className={Styles.primary}
    >
      {children}
    </Button>
  );
}
