import React from "react";
import { Fade } from "react-reveal";
import Styles from "./terms.module.scss";

export default function Privacy() {
  return (
    <Fade bottom cascade>
      <div className={Styles.container}>
        <h2>Privacy policy</h2>
        <h3>Industry-leading security measures</h3>
        <p>
          Our trials and triumphs became at once unique and universal, black and
          more than black; in chronicling our journey, the stories and songs
          gave us a means to reclaim memories that we didn't need to feel shame
          about...memories that all people might study and cherish - and with
          which we could start to rebuild." In all nations - including my own -
          this change can bring fear.
        </p>
        <p>
          Louis, and thousands more like her, who has the grades, has the drive,
          has the will, but doesn't have the money to go to college. But it also
          comes from my own American story. I am the son of a black man from
          Kenya and a white woman from Kansas. We need a President who can face
          the threats of the future, not keep grasping at the ideas of the past.
        </p>
        <Fade bottom cascade>
          <div>
            <h3>Industry-leading security measures</h3>
            <p>
              Our trials and triumphs became at once unique and universal, black
              and more than black; in chronicling our journey, the stories and
              songs gave us a means to reclaim memories that we didn't need to
              feel shame about...memories that all people might study and
              cherish - and with which we could start to rebuild." In all
              nations - including my own - this change can bring fear.
            </p>
            <p>
              Louis, and thousands more like her, who has the grades, has the
              drive, has the will, but doesn't have the money to go to college.
              But it also comes from my own American story. I am the son of a
              black man from Kenya and a white woman from Kansas. We need a
              President who can face the threats of the future, not keep
              grasping at the ideas of the past.
            </p>
          </div>
        </Fade>
        <Fade bottom cascade>
          <div>
            <h3>Industry-leading security measures</h3>
            <p>
              Our trials and triumphs became at once unique and universal, black
              and more than black; in chronicling our journey, the stories and
              songs gave us a means to reclaim memories that we didn't need to
              feel shame about...memories that all people might study and
              cherish - and with which we could start to rebuild." In all
              nations - including my own - this change can bring fear.
            </p>
            <p>
              Louis, and thousands more like her, who has the grades, has the
              drive, has the will, but doesn't have the money to go to college.
              But it also comes from my own American story. I am the son of a
              black man from Kenya and a white woman from Kansas. We need a
              President who can face the threats of the future, not keep
              grasping at the ideas of the past.
            </p>
          </div>
        </Fade>
      </div>
    </Fade>
  );
}
