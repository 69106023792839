import React, { ReactNode } from "react";
import { Button } from "semantic-ui-react";
import Styles from "./button.module.scss";

interface BtnProps {
  children: ReactNode;
  link?: string;
}

export default function SecondaryBtn({ children, link }, others: BtnProps) {
  return (
    <Button
      onClick={() => window.open(link as string, "_blank")}
      {...others}
      className={Styles.secondary}
    >
      {children}
    </Button>
  );
}
