import React, { useEffect, useState } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import Styles from "./whyPayInvert.module.scss"
import Dashboard from "../../../assets/dashboard.png"
import OrderSummary from "../../../assets/order_summary.png"
import CreditCardImg from "../../../assets/credit_card_computer.png"

export default function WhyPayInvert() {
    const [offsetY, setOffsetY] = useState(0)
    const [lastScrollY, setLastScrollY] = useState(0);
    // set page scrolled
    const handleScroll = () => {
        if (typeof window !== "undefined") {
            setOffsetY(window.scrollY)
            setLastScrollY(window.scrollY);
        }
    }
    // listen to page scroll
    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener('scroll', handleScroll)
            return window.removeEventListener("scroll", handleScroll)
        }
    }, [lastScrollY])

    useEffect(() => {
        console.log(offsetY)
    }, [offsetY])

    return (
        <Grid relaxed stackable columns="equal" className={Styles.container}>
            <Grid.Row>
                <Grid.Column className="no-padding-left" width={9} >
                    <div className={Styles.dashboardImgWrapper}>
                        <Image data-aos="zoom-out-up" data-aos-offset="-200" src={Dashboard} className={Styles.dashboard} />
                        <Image style={{ transform: `translateY(${offsetY * 22}px)` }} data-aos="zoom-out-up" data-aos-delay={50} src={OrderSummary} className={Styles.summary} />
                        <Image data-aos="zoom-out-up" data-aos-delay={100} src={CreditCardImg} className={Styles.creditCard} />
                    </div>
                </Grid.Column>
                <Grid.Column width={7}>
                    <div className={Styles.content}>
                        <h2 data-aos="fade-bottom">Why PayInvert?</h2>
                        <p data-aos="fade-bottom" data-aos-delay={50} className={Styles.subHeader}>Your trusted technology partner for business growth</p>
                        <div>
                            <p data-aos="fade-bottom" data-aos-delay={100}>Accepting payments is the foundation of doing business. Money moves and must be collected and put away securely. But businesses trust their growth to technology. So that far beyond just helping them accept payments, technology has to support the sort of unified commerce that accelerates and sustains growth. </p>
                            <p data-aos="fade-bottom" data-aos-delay={150}>Rather than leave it to businesses to cobble
                                together a set of varied solutions, we have
                                "inverted" payment technologies. We built PayInvert from the ground up, like a suite that has to be kept Live in a reciprocating engine to progressively enable business growth worldwide.</p>
                        </div>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}
