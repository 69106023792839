import React from "react";
import { Grid } from "semantic-ui-react";
import PrimaryBtn from "../buttons/primaryBtn";
import SecondaryBtn from "../buttons/secondaryBtn.tsx";
import Styles from "./cta.module.scss";

export default function CTASection() {
  return (
    <Grid stackable className={Styles.container}>
      <Grid.Row>
        <Grid.Column width={10}>
          <div className={Styles.ctaTitle}>
            <h1 data-aos="fade-bottom">Ready For PayInvert?</h1>
          </div>
        </Grid.Column>
        <Grid.Column className={Styles.content} width={6}>
          <p data-aos="fade-bottom" data-aos-delay={50}>
            Create a PayInvert account instantly and start accepting secure
            payments or contact our sales team to learn more.
          </p>
          <div
            data-aos="fade"
            data-aos-delay={100}
            className={Styles.btnWrapper}
          >
            <PrimaryBtn link="https://merchant.payinvert.com/sign-up">
              Get started
            </PrimaryBtn>
            <SecondaryBtn link="mailto:sales@payinvert.com">
              Contact sales
            </SecondaryBtn>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
